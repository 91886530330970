import React from "react";
import "./Timeline.css";

const Timeline = ({ data }) => {
  return (
    <div id="hw__timeline">
      {data.map((item) => (
        <div className="hw__timeline-item" key={`timeline-${item.title}`}>
          <div className="hw__timeline-item-left">
            <div className="hw__timeline-logo">
              <img
                src={item.logo}
                alt="Organisation logo"
                style={item.logoStyle}
              />
            </div>
            <div className="hw__timeline-line" />
          </div>

          <div className="hw__timeline-item-right">
            <div className="hw__timeline-period">{item.period}</div>
            <div className="hw__timeline-title">
              <a className="hw__link-text" href={item.link}>
                {item.title}
              </a>
            </div>
            <div className="hw__timeline-subtitle">{item.subtitle}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;
