import React, { useState, useEffect } from "react";
import "./BackToTop.css";
import { BsTriangleFill } from "react-icons/bs";

function BackToTop() {
  const [showScroll, setShowScroll] = useState(false);
  const [scale, setScale] = useState(0);

  useEffect(() => {
    // 监听页面滚动事件，如果滚动高度大于 500，则显示返回顶部按钮
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 500) {
        setShowScroll(true);
        // 按钮大小
        setScale(1);
      } else if (showScroll && window.pageYOffset <= 500) {
        setShowScroll(false);
        // 按钮大小
        setScale(0);
      }
    };

    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [showScroll]);

  return (
    <>
      {/* 返回顶部按钮 */}
      {
        <button
          className="hw__backtotop"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          style={{ transform: `scale(${scale})` }}
        >
          <BsTriangleFill />
        </button>
      }
    </>
  );
}

export default BackToTop;
