import { useEffect } from "react";

const ASCIIArt = () => {
  useEffect(() => {
    // 控制台打印自定义内容
    if (window.console) {
      console.log(
        `%c
                                                                                        
  ██╗  ██╗ █████╗ ██████╗ ██████╗ ██╗███████╗    ██╗    ██╗ ██████╗ ███╗   ██╗ ██████╗  
  ██║  ██║██╔══██╗██╔══██╗██╔══██╗██║██╔════╝    ██║    ██║██╔═══██╗████╗  ██║██╔════╝  
  ███████║███████║██████╔╝██████╔╝██║███████╗    ██║ █╗ ██║██║   ██║██╔██╗ ██║██║  ███╗ 
  ██╔══██║██╔══██║██╔══██╗██╔══██╗██║╚════██║    ██║███╗██║██║   ██║██║╚██╗██║██║   ██║ 
  ██║  ██║██║  ██║██║  ██║██║  ██║██║███████║    ╚███╔███╔╝╚██████╔╝██║ ╚████║╚██████╔╝ 
  ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝╚══════╝     ╚══╝╚══╝  ╚═════╝ ╚═╝  ╚═══╝ ╚═════╝  
                                                                                        `,
        `
      background: linear-gradient(45deg, #1fc7b6, #1fc7b680);
      color: white;
      `
      );
    }
  });
};

export default ASCIIArt;
