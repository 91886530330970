import React from "react";
import useScroll from "../../hooks/useScroll";
import Section from "../../components/Section/Section";
import Timeline from "../../components/Timeline/Timeline";
import workImg from "../../assets/Work/Work.svg";
import "./Work.css";
import info from "../../data/Info";

const Work = () => {
  useScroll("hw__work", "animate__fadeInUp");

  return (
    <Section title="work" width="1000px" desc="">
      <div className="hw__work">
        <div className="hw__work-illustration">
          <img src={workImg} alt="work" />
        </div>

        <Timeline data={info.work} />
      </div>
    </Section>
  );
};

export default Work;
