import React from "react"
const SideBarContext = React.createContext()

const SideBarContextProvider = (props) =>  {
  const [isSideBarOpen, setIsSideBarOpen] = React.useState(false);

  const toggleSideBarVisibility = (openOrhide) => {
    setIsSideBarOpen(openOrhide)
  }

  return (
    <SideBarContext.Provider value={{isSideBarOpen, toggleSideBarVisibility}}>
      {props.children}
    </SideBarContext.Provider>
  )
}

export {SideBarContextProvider, SideBarContext}