import React from "react";
import Section from "../../components/Section/Section";
import "./Skills.css";
import info from "../../data/Info";

const SkillBox = (skill, index) => {
  return (
    <div
      className="hw__skills-icon"
      style={{
        backgroundColor: skill.themeColor || "var(--color-skills-icon-bg)",
      }}
      key={`skill-${index + 1}`}
    >
      <img src={skill.img} alt={skill.name} />
    </div>
  );
};

const SkillBoxMini = (skill, index) => {
  return (
    <div
      className="hw__skills-icon-mini"
      key={`skill-${index + 1}`}
      style={{
        backgroundColor: skill.themeColor || "var(--color-skills-icon-bg)",
        color: skill.themeColor ? "white" : "",
      }}
    >
      <img src={skill.img} alt={skill.name} />
      <div className="hw__skills-icon-mini-name">{skill.name}</div>
    </div>
  );
};

// const SkillBar = (skill, index) => {
//   return (
//     <div className="hw__skills-bar" key={`skill-${index + 1}`}>
//       <div className="hw__skills-bar-text">
//         <div className="hw__skills-bar-text-name">{skill.name}</div>
//         <div className="hw__skills-bar-text-percentage">
//           {skill.percentage}%
//         </div>
//       </div>

//       <div className="hw__skills-bar-progress">
//         <div
//           className="hw__skills-bar-progress-colored animate__animated animate__slideInLeft animate__delay-3s"
//           style={{ width: `${skill.percentage}%` }}
//         />
//       </div>
//     </div>
//   );
// };

const Skills = () => {
  // const SkillsDataFiltered = info.skills.filter(
  //   (skill) => skill.percentage != null
  // );

  return (
    <Section title="skills">
      <div className="hw__skills-boxes-group">
        <div className="hw__skills-boxes">
          {info.skills.map((skill, index) => SkillBox(skill, index))}
          {info.skills.map((skill, index) => SkillBox(skill, index))}
        </div>

        <div className="hw__skills-scroll-box lenis">
          <div className="hw__skills-boxes-mini">
            {info.skills.map((skill, index) => SkillBoxMini(skill, index))}
          </div>
        </div>
      </div>

      {/* <div className="hw__skills-bars">
        {SkillsDataFiltered.map((skill, index) => SkillBar(skill, index))}
      </div> */}
    </Section>
  );
};

export default Skills;
