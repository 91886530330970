import React, { useState } from "react";
import "./Projects.css";
import Section from "../../components/Section/Section";
import useScroll from "../../hooks/useScroll";
import LazyLoad from "react-lazyload";
import { FiExternalLink, FiGithub } from "react-icons/fi";
import info from "../../data/Info";

const ProjectItem = (project, index) => {
  return (
    <div className="hw__projects-item" key={`project-${index + 1}`}>
      <div className="hw__projects-item-card">
        <div className="hw__projects-item-img">
          {project.img ? (
            <LazyLoad once offset={100}>
              <img src={project.img} alt={project.title} />
            </LazyLoad>
          ) : (
            <div
              style={{
                backgroundColor: "var(--color-grey)",
                width: "100%",
                height: "100%",
              }}
            ></div>
          )}
        </div>

        <div className="hw__projects-content">
          <div className="hw__projects-title">{project.title}</div>
          <p className="hw__projects-desc">{project.desc}</p>
          <div className="hw__projects-link">
            <a
              href={project.demo}
              alt="demo"
              target="_blank"
              rel="noreferrer"
              className="hw__projects-link-demo"
            >
              <FiExternalLink />
            </a>
            {project.github && (
              <a
                href={project.github}
                alt="github"
                target="_blank"
                rel="noreferrer"
                className="hw__projects-link-github"
              >
                <FiGithub />
              </a>
            )}
          </div>
        </div>
      </div>

      {project.techs && (
        <div className="hw__projects-item-techs">
          {project.techs.map((tech) => (
            <div className="hw__projects-item-tech" key={tech}>
              {tech}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Tab = (tab, index, selected, setSelected) => {
  return (
    <div
      onClick={() => setSelected(index)}
      className={`hw__projects-tab${
        selected === index ? " hw__projects-tab-selected" : ""
      }`}
      key={`project-tab-${index + 1}`}
    >
      {tab.type}
      <sup>{info.projects[index].data.length}</sup>
    </div>
  );
};

const Projects = () => {
  useScroll("hw__projects-item", "animate__zoomIn");
  const [selected, setSelected] = useState(0);

  return (
    <Section title="projects">
      <div className="hw__projects-tabs">
        {info.projects.map((tab, index) =>
          Tab(tab, index, selected, setSelected)
        )}
      </div>

      <p className="hw__projects-tab-desc">{info.projects[selected].desc}</p>

      <div className="hw__projects-items">
        {info.projects[selected].data.map((project, index) =>
          ProjectItem(project, index)
        )}
      </div>
    </Section>
  );
};

export default Projects;
