import React from "react";
import "./SideBar.css";
import { ImCross } from "react-icons/im";
import { SideBarContext } from "../../contexts/SideBarContext";
import info from "../../data/Info";

const SideBar = () => {
  const context = React.useContext(SideBarContext);

  return (
    <div
      className={`hw__sidebar${
        context.isSideBarOpen ? " hw__sidebar-show" : ""
      }`}
    >
      <div
        className="hw__cross"
        onClick={() => context.toggleSideBarVisibility(false)}
      >
        <ImCross />
      </div>
      <ul>
        {info.sections.map((item, index) => (
          <li key={`menu-${index + 1}`}>
            <a
              className="hw__link-text hw__font-georgia"
              href={`#hw__${item}`}
              onClick={() => context.toggleSideBarVisibility(false)}
              rel="noopener noreferrer"
            >
              {item}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideBar;
