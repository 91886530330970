import Soton from "../assets/Education/Soton.png";
import CTGU from "../assets/Education/CTGU.png";
import RadicalAI from "../assets/Work/Radical AI.png";
import JoinMomentum from "../assets/Work/Join Momentum.png";
import CSS3 from "../assets/Skills/CSS3.png";
import HTML5 from "../assets/Skills/HTML5.png";
import JavaScript from "../assets/Skills/JavaScript.png";
import Premiere from "../assets/Skills/Premiere.svg";
import Python from "../assets/Skills/Python.svg";
import LogicPro from "../assets/Skills/LogicPro.png";
import React from "../assets/Skills/React.svg";
import Redux from "../assets/Skills/Redux.png";
import Vue from "../assets/Skills/Vue.svg";
import Git from "../assets/Skills/Git.png";
import Figma from "../assets/Skills/Figma.svg";
import FinalCutPro from "../assets/Skills/FinalCutPro.png";
import Less from "../assets/Skills/Less.png";
import SASS from "../assets/Skills/Sass.png";
import TypeScript from "../assets/Skills/TypeScript.svg";
import NextJS from "../assets/Skills/NextJS.svg";
import NodeJS from "../assets/Skills/NodeJS.png";
import MongoDB from "../assets/Skills/MongoDB.png";
import Flask from "../assets/Skills/Flask.png";
import Tailwind from "../assets/Skills/Tailwind.png";
import Gericht from "../assets/Projects/Gericht.png";
import ClearVision from "../assets/Projects/ClearVision.png";
import FindAService from "../assets/Projects/FindAService.png";
import RezaHomayuni from "../assets/Testimonials/Reza Homayuni.jpeg";

import { RiInstagramFill } from "react-icons/ri";
import { FaLinkedinIn, FaLinkedin, FaBloggerB } from "react-icons/fa";
import { TbBrandGithubFilled } from "react-icons/tb";

const info = {
  jobTitles: [
    "Frontend Developer 🖥️",
    "Software Engineer 💻",
    "Content Creator 📰",
    "Cover Singer 🎤",
  ],
  description:
    "An innovative and detail-oriented Software Engineering graduate with 4 years of academic experience and 2 internships in web development using JS and React. Enjoy building beautiful, user-friendly, and performant software.",
  socialLinks: {
    LinkedIn: {
      link: "https://www.linkedin.com/in/harriswong89/",
      icon: <FaLinkedin />,
    },
    about: [
      {
        name: "Blog",
        icon: <FaBloggerB />,
        link: "https://blog.harriswong.top/",
      },
      {
        name: "GitHub",
        icon: <TbBrandGithubFilled />,
        link: "https://github.com/harrisblog",
      },
      {
        name: "LinkedIn",
        link: "https://www.linkedin.com/in/harriswong89/",
        icon: <FaLinkedinIn />,
      },
    ],
    footer: [
      {
        name: "Blog",
        icon: <FaBloggerB />,
        link: "https://blog.harriswong.top/",
      },
      {
        name: "GitHub",
        icon: <TbBrandGithubFilled />,
        link: "https://github.com/harrisblog",
      },
      {
        name: "Instagram",
        link: "https://www.instagram.com/__hss0809__/",
        icon: <RiInstagramFill />,
      },
    ],
  },

  education: [
    {
      period: "Sep. 2022 - Nov. 2023",
      title: "University of Southampton",
      subtitle: "MSc in Software Engineering with Merit (2:1)",
      link: "https://www.southampton.ac.uk/",
      logo: Soton,
      logoStyle: {
        height: "75%",
        width: "75%",
        transform: "translateY(2px)",
      },
    },
    {
      period: "Sep. 2018 - Jun. 2022",
      title: "China Three Gorges University",
      subtitle: "BSc in Computer Science",
      link: "https://www.ctgu.edu.cn/",
      logo: CTGU,
    },
  ],
  sections: [
    "about",
    "skills",
    "education",
    "work",
    "projects",
    "testimonials",
    "contact",
  ],
  projects: [
    {
      type: "Featured Projects",
      desc: "These are some featured web projects.",
      data: [
        {
          title: "Portfolio",
          img: "https://image.thum.io/get/width/400/crop/800/allowJPG/wait/6/noanimate/https://harriswong.top",
          demo: "https://harriswong.top",
          desc: "A website to showcase my skills and projects.",
          techs: ["React", "CSS", "JS"],
        },
        {
          title: "New Blog",
          img: "https://image.thum.io/get/width/400/crop/800/allowJPG/wait/6/noanimate/https://blog.harriswong.top",
          demo: "https://blog.harriswong.top",
          desc: "A brand new blog that I built in late 2023. I mainly use it to record notes from my studies, bits and pieces of my life and to share some experiences and ideas.",
          techs: ["React", "SCSS", "Next.js", "TS"],
        },
        {
          title: "ClearVision",
          img: ClearVision,
          demo: "https://cv.harriswong.top",
          desc: "A CV builder website that allows users to create their CVs using predefined templates and also integrates with ChatGPT to provide CV smart optimisation and CV content generation.",
          techs: ["Python", "React", "Redux", "Less", "JS", "MUI"],
        },
        {
          title: "Find A Service",
          img: FindAService,
          demo: "https://fas.harriswong.top",
          desc: "A website that allows users to search for nearby services, such as cleaning, babysitting, pest control, plumbing, etc.",
          techs: ["MERN", "Ant Design"],
        },
        {
          title: "Gericht Restaurant",
          img: Gericht,
          demo: "https://gericht.harriswong.top",
          github: "https://github.com/harrisblog/Gericht_Restaurant",
          desc: "This is a restaurant website that I built following a tutorial by JavaScript Mastery on youtube.",
          techs: ["React", "CSS", "JS"],
        },
      ],
    },
    {
      type: "UI Design",
      desc: "These are the UIs I designed with Figma, including web and mobile.",
      data: [
        {
          title: "Hire Direct",
          img: "",
          demo: "https://www.figma.com/file/HiKiioqPnhyY6dRbE7C4Eg/Hire-Direct",
          desc: "An app that offers car rental services.",
          techs: ["Figma"],
        },
        {
          title: "Find A Service",
          img: "",
          demo: "https://www.figma.com/file/Lk2rWzz8sd4NBkE5gF7vbJ/Find-A-Service?type=design&t=aSIqRdrDFamBAE85-1",
          desc: "A website that allows users to search for nearby services, such as cleaning, babysitting, pest control, plumbing, etc.",
          techs: ["Figma"],
        },
        {
          title: "CozyHaven",
          img: "",
          demo: "https://www.figma.com/file/4UqOG2dpv2VJDAdfwLF15Q/CozyHaven?type=design&t=aSIqRdrDFamBAE85-1",
          desc: "A house looking platform that allows users to search the most suitable living area.",
          techs: ["Figma"],
        },
      ],
    },
    {
      type: "Hobby Projects",
      desc: "In some of these web projects, I mainly modified lots of CSS code to make them more beautiful and friendly to use. Others were built while I was learning the technology.",
      data: [
        {
          title: "Old Blog",
          img: "https://cdn.jsdelivr.net/npm/hassan-assets/img/siteshot.jpg",
          demo: "https://old-blog.harriswong.top",
          desc: "A Hexo Butterfly theme blog. I mainly use it to record notes from my studies, bits and pieces of my life and to share some experiences and ideas.",
          techs: ["Hexo", "Butterfly Theme"],
        },
        {
          title: "Music Box",
          img: "https://image.thum.io/get/width/400/crop/800/allowJPG/wait/6/noanimate/https://mb.harriswong.top",
          demo: "https://mb.harriswong.top/#/discovery",
          github: "https://github.com/harrisblog/MusicBox",
          desc: "A music player based on the Vue framework to call the NetEase cloud interface.",
          techs: ["Vue", "CSS", "JS", "Element UI"],
        },
        {
          title: "Song List",
          img: "https://image.thum.io/get/width/400/crop/800/allowJPG/wait/6/noanimate/https://sl.harriswong.top",
          demo: "https://sl.harriswong.top/",
          github: "https://github.com/harrisblog/SongList",
          desc: "A static music player based on Aplayer and MetingJS.",
          techs: ["HTML", "CSS", "JS"],
        },
        {
          title: "YMTS",
          img: "https://image.thum.io/get/width/400/crop/800/allowJPG/wait/6/noanimate/https://ymts.vercel.app",
          demo: "https://ymts.vercel.app/home",
          github: "https://github.com/harrisblog/YMTS",
          desc: "A study abroad agency website. (Since there is no cloud server to provide back-end services, the pages that need to use back-end data have been removed temporarily)",
          techs: ["Vue", "CSS", "JS", "Element Plus", "Echarts"],
        },
        {
          title: "Navigation",
          img: "https://image.thum.io/get/width/400/crop/800/allowJPG/wait/6/noanimate/https://navi.harriswong.top",
          demo: "https://navi.harriswong.top",
          github: "https://github.com/harrisblog/navigation",
          desc: "A navigation website that includes links that I usually visit.",
          techs: ["Hexo", "Webstack Theme"],
        },
        {
          title: "Docsify",
          img: "https://image.thum.io/get/width/400/crop/800/allowJPG/wait/6/noanimate/https://doc.harriswong.top",
          demo: "https://doc.harriswong.top",
          github: "https://github.com/harrisblog/docsify",
          desc: "Online document website based on docsify.",
          techs: ["docsify"],
        },
      ],
    },
  ],
  skills: [
    {
      name: "HTML5",
      themeColor: "#f06529",
      img: HTML5,
      percentage: 85,
    },
    {
      name: "CSS3",
      themeColor: "#2965f1",
      img: CSS3,
      percentage: 85,
    },
    {
      name: "SASS/SCSS",
      themeColor: "#c69",
      img: SASS,
    },
    {
      name: "Less",
      themeColor: "#1d365d",
      img: Less,
    },
    {
      name: "Tailwind CSS",
      themeColor: "#39bef8",
      img: Tailwind,
    },
    {
      name: "JavaScript",
      themeColor: "#e7d038",
      img: JavaScript,
      percentage: 80,
    },
    {
      name: "TypeScript",
      themeColor: "#3178c6",
      img: TypeScript,
    },
    {
      name: "React",
      themeColor: "#0074a6",
      img: React,
      percentage: 80,
    },
    {
      name: "Redux",
      themeColor: "#704cb6",
      img: Redux,
    },
    {
      name: "Next.js",
      themeColor: "#000",
      img: NextJS,
    },
    {
      name: "Node.js",
      img: NodeJS,
    },
    {
      name: "MongoDB",
      themeColor: "#3FA037",
      img: MongoDB,
    },
    {
      name: "Figma",
      img: Figma,
    },
    {
      name: "Vue",
      img: Vue,
    },
    {
      name: "Git",
      themeColor: "#df5c3e",
      img: Git,
    },
    {
      name: "Python",
      img: Python,
    },
    {
      name: "Flask",
      img: Flask,
      themeColor: "#999",
    },
    {
      name: "Premiere",
      themeColor: "#9999f8",
      img: Premiere,
    },
    {
      name: "Logic Pro",
      themeColor: "#404040",
      img: LogicPro,
    },
    {
      name: "Final Cut Pro",
      themeColor: "#404040",
      img: FinalCutPro,
    },
  ],
  work: [
    {
      period: "Jul. 2024 - PRESENT",
      title: "Join Momentum",
      subtitle: "Frontend Developer Intern",
      link: "https://www.joinmomentum.co.uk/",
      logo: JoinMomentum,
      logoStyle: {
        height: "75%",
        width: "75%",
      },
    },
    {
      period: "Jun. 2024 - PRESENT",
      title: "Radical AI",
      subtitle: "Software Developer Intern",
      link: "https://lab.radicalai.app/",
      logo: RadicalAI,
    },
  ],
  testimonials: [
    {
      name: "Reza Homayuni",
      img: RezaHomayuni,
      title: "Software Developer @ Radical AI",
      comment: `I had the pleasure of leading Shisong and working closely with him. As a frontend developer, he excels at building reusable user interface components and implementing complex business logic. Shisong's ability to explain technical concepts clearly, combined with his eagerness to learn new technologies, made him an invaluable asset to our team. His impressive adaptability allowed him to seamlessly contribute to our large open-source codebase.

I highly recommend Shisong for any frontend developer position.`,
    },
    {
      name: "You Could Be Next",
      img: null,
      title: `SDE @ Big Tech Company`,
      comment:
        "Working with Shisong was a game-changer! Your feedback here could inspire the next big innovation. Ready to share your thoughts?",
    },
  ],
};

export default info;
