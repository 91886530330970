import React from "react";
import Section from "../../components/Section/Section";
import "./Education.css";
import educationImg from "../../assets/Education/Education.svg";
import useScroll from "../../hooks/useScroll";
import Timeline from "../../components/Timeline/Timeline";
import info from "../../data/Info";

const Education = () => {
  useScroll("hw__education", "animate__fadeInUp");

  return (
    <Section
      title="education"
      width="1000px"
      desc="My interest in frontend development dates back to my undergraduate years when I enjoyed changing the appearance of Hexo Butterfly theme blog. I would get a great sense of satisfaction and achievement when I successfully implemented a UI or functionality, which also taught me a lot about Frontend knowledge."
    >
      <div className="hw__education">
        <Timeline data={info.education} />

        <div className="hw__education-illustration">
          <img src={educationImg} alt="education" />
        </div>
      </div>
    </Section>
  );
};

export default Education;
