import React from "react";
import "./Wave.css";
import wave1 from "../../assets/Section/Wave1.svg";
import wave2 from "../../assets/Section/Wave2.svg";
import wave1Dark from "../../assets/Section/Wave1Dark.svg";
import wave2Dark from "../../assets/Section/Wave2Dark.svg";

const Wave = (props) => {
  return (
    <div className="hw__wave">
      <img
        src={props.isDark ? wave1Dark : wave1}
        alt=""
        className="hw__wave1"
      />
      <div className="hw__wave-bg">{props.children}</div>
      <img
        src={props.isDark ? wave2Dark : wave2}
        alt=""
        className="hw__wave2"
      />
    </div>
  );
};

export default Wave;
