import React from "react";
import "./About.css";
import me from "../../assets/About/Me.png";
import ScrollingText from "../../components/ScrollingText/ScrollingText";
import info from "../../data/Info";
import Button from "../../components/Button/Button";

const About = () => {
  return (
    <section id="hw__about">
      <div className="hw__about-content">
        <p className="animate__animated animate__fadeInUp">Hiya 👋 I'm</p>

        <p className="hw__about-name animate__animated animate__fadeInUp">
          Harris Wong (Shisong),
        </p>

        <ScrollingText
          texts={info.jobTitles}
          className="hw__about-jobtitles animate__animated animate__fadeInUp animate__delay-1s"
        />

        <p className="hw__about-desc animate__animated animate__fadeInUp animate__delay-2s">
          {info.description}
        </p>

        <div className="hw__about-links animate__animated animate__fadeInUp animate__delay-2s">
          {info.socialLinks.about.map((item) => (
            <Button
              key={item.name}
              tooltip={item.name}
              link={item.link}
              size="large"
              variant="outlined"
              icon={item.icon}
            />
          ))}
        </div>
      </div>

      <img
        src={me}
        className="hw__about-img"
        alt="Harris Wong (Shisong) Portrait"
      />
    </section>
  );
};

export default About;
