import React from "react";
import "./Section.css";

const Section = ({ width = "1200px", title, desc, children }) => {
  return (
    <section
      id={`hw__${title}`}
      style={{ maxWidth: width }}
      className="hw__section"
    >
      <header>{title}</header>

      {desc && <p className="hw__section-desc">{desc}</p>}

      {children}
    </section>
  );
};

export default Section;
