import React from "react";
import "./Button.css";

// Default is contained, medium
const Button = ({ name, tooltip, icon, link, variant, size }) => (
  <a
    href={link}
    className={`hw__button${
      variant === "outlined" ? " hw__button-outlined" : " hw__button-contained"
    }${size === "large" ? " hw__button-large" : " hw__button-medium"}${
      name ? "" : " hw__button-notext"
    }`}
    title={tooltip}
    target="_blank"
    rel="noreferrer"
  >
    {icon}
    {name && <span>{name}</span>}
  </a>
);

export default Button;
