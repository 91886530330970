import React from "react";
import "./Footer.css";
import info from "../../data/Info";
import Button from "../Button/Button";

const Daisy8 = (
  <svg
    id="daisy8"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
  >
    <path
      id="daisy8-s-path1"
      d="M180,20c32.312602,9.764873-4.879192,94.125244,0,100c5.80847,5.355265,86.735225-50.153013,100.999999-10C288.550021,138.918574,184.20248,142.293996,185,152.19683c-.154528,5.878742,95.292542,26.917415,84.999999,57.80317C258.068875,232.071481,185.46543,189.126172,180,190c-22.497247-.137825,52.007783,95.88834,0,98-27.547679,1.472144-29.056529-88.238989-39.000001-88C133.620689,199.204911,93.938734,301.044088,58,274c-26.92531-22.66049,59.675306-88.559468,52-94-12.465663-6.396793-74.829214,40.60033-90,10-7.596363-24.809261,79.290469-34.258605,80-38.0374c1.347606-7.277963-87.782829-11.64628-90-31.9626.33519-36.45788,94.62676,11.60301,100,10.22785C113.89878,127.80525,28.39687,56.57623,60,40c30.991063-14.315408,73.477129,71.793281,80,71c6.043359.460628-4.765278-98.447591,40-91ZM150.01515,130.25276C137.26171,129.67895,124.23658,139.04722,125,150c1.17643,11.58438,11.16798,25.87242,25.01515,24c11.77702-.59908,21.36607-13.91414,20.98485-24-.18941-15.64422-14.83631-20.17276-20.98485-19.74724Z"
      transform="translate(.000001 0)"
    />
  </svg>
);

const Butterfly = (
  <svg
    id="butterfly"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 215"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
  >
    <g transform="matrix(1.142928 0 0 1.14292-18.42234-42.728207)">
      <path
        d="M232.7,50.48C229,45.7,221.84,40,209,40c-16.85,0-38.46,11.28-57.81,30.16-5.532446,5.362373-10.613014,11.172081-15.19,17.37l-.369018-27.355232c5.378629-2.265563,15.995244-12.72418,9.881001-20.554762C139.084052,31.38768,129.047314,46.737459,128,48c-.98572-1.284989-11.864561-16.571971-18.721736-8.338235-6.33442,7.606039,5.365345,18.239653,10.773623,20.593308L120,87.53c-4.564241-6.196365-9.631392-12.006028-15.15-17.37C85.49,51.28,63.88,40,47,40c-12.84,0-20,5.7-23.7,10.48-6.82,8.77-12.18,24.08-.21,71.2c6.05,23.83,19.51,33,30.63,36.42-12.618659,20.490013-6.436017,47.316288,13.878033,60.216311s47.223033,7.087911,60.401967-13.046311c13.178934,20.134222,40.087918,25.946334,60.401967,13.046311s26.496692-39.726298,13.878033-60.216311c11.12-3.4,24.57-12.59,30.63-36.42c6.72-26.44,11.94-55.58-.21-71.2ZM92,208c-14.726339-.258241-26.758304-11.835918-27.582863-26.541422s9.837702-27.555678,24.442863-29.458578c2.882318-.263725,7.5839-2.209612,10.073745-5.256004s1.094184-5.693936-2.203313-7.489404-5.500214-1.304021-8.855799-1.015747C81.098293,139.340159,73.022424,143.991631,66.36,144c-6.205764-.028557-21.973907-3.58134-27.76-26.26C35.72,106.39,27,71.86,35.94,60.3C37.37,58.46,40.09,56,47,56c27.27,0,73,44.88,73,71.67v52.33c0,15.463973-12.536027,28-28,28Zm125.4-90.26c-5.761563,22.69-18.360811,24.903699-26.997596,24.823699-7.1526.017383-14.803107-3.892691-22.327795-4.941153-3.453481-.364904-7.637761.056641-9.695521,1.632046s-4.129795,4.611098-1.651504,7.489404s8.040015,4.747309,10.402416,5.206004c15.03147,1.624411,26.085623,14.847371,25.019889,29.92875s-13.869567,26.618402-28.980099,26.11281-27.114543-12.872673-27.16979-27.99156v-52.33C136,100.88,181.69,56,209,56c6.95,0,9.66,2.46,11.1,4.3c8.95,11.56.18,46.09-2.7,57.44Z"
        transform="translate(.102998 0.163372)"
        stroke="#000"
        strokeWidth="0"
      />
      <path
        d="M96.239795,154.542936c-4.026895-4.253528-16.178189,1.173413-19.006837,7.78612-4.456333,10.417848-3.915335,23.994487-1.973036,25.618298c5.53718,4.04224,25.30876-28.093103,20.979873-33.404418Z"
        transform="matrix(1 0 0-1 70.357698 353.009656)"
        strokeWidth="0.512"
      />
      <path
        d="M93.520073,159.483279c-.654238-2.617885-3.844506-12.596894-8.37095-7.446423-5.251509,5.975496-18.536272,28.968193-15.75938,32.23353c3.974344,4.383312,28.140946-9.705762,24.13033-24.787107Z"
        transform="matrix(1 0 0-1 90.596272 344.235976)"
        strokeWidth="0.512"
      />
      <path
        d="M96.239795,154.542936c-4.026895-4.253528-16.178189,1.173413-19.006837,7.78612-4.456333,10.417848-3.915335,23.994487-1.973036,25.618298c5.53718,4.04224,25.30876-28.093103,20.979873-33.404418Z"
        transform="matrix(-1 0 0-1 186.234716 353.009656)"
        strokeWidth="0.512"
      />
      <path
        d="M93.520073,159.483279c-.654238-2.617885-3.844506-12.596894-8.37095-7.446423-5.251509,5.975496-18.536272,28.968193-15.75938,32.23353c3.974344,4.383312,28.140946-9.705762,24.13033-24.787107Z"
        transform="matrix(-1 0 0-1 165.596223 344.845504)"
        strokeWidth="0.512"
      />
      <path
        d="M154.337456,109.065786C158.757757,114.861602,221.672122,77.030487,209,64.404323c-7.114457-6.653631-59.141308,38.654735-54.662544,44.661463Z"
        transform="translate(.08241-.659287)"
        strokeWidth="0.512"
      />
      <path
        d="M187.124991,104.638346c.02462-4.45874,23.372191-24.405808,25.942083-20.336108c1.86854,2.928923,1.12832,13.108553-.729398,17.820776-2.255907,4.511839-25.13983,6.730184-25.212685,2.515332Z"
        transform="translate(-.04231 1.590718)"
        strokeWidth="0.512"
      />
      <path
        d="M153.918669,120.205459c2.257152-6.240698,42.420094-4.866941,49.160854-3.881138c5.578506.999979-2.82348,13.956459-10.04314,15.572085-2.652766.486181-43.730142,2.340457-39.117714-11.690947Z"
        transform="translate(1.97823 0.197824)"
        strokeWidth="0.512"
      />
      <path
        d="M154.337456,109.065786C158.757757,114.861602,221.672122,77.030487,209,64.404323c-7.114457-6.653631-59.141308,38.654735-54.662544,44.661463Z"
        transform="matrix(-1 0 0 1 254.796589-.334213)"
        strokeWidth="0.512"
      />
      <path
        d="M187.124991,104.638346c.02462-4.45874,23.372191-24.405808,25.942083-20.336108c1.86854,2.928923,1.12832,13.108553-.729398,17.820776-2.255907,4.511839-25.13983,6.730184-25.212685,2.515332Z"
        transform="matrix(-1 0 0 1 256.420092 1.946459)"
        strokeWidth="0.512"
      />
      <path
        d="M153.918669,120.205459c2.257152-6.240698,42.420094-4.866941,49.160854-3.881138c5.578506.999979-2.82348,13.956459-10.04314,15.572085-2.652766.486181-43.730142,2.340457-39.117714-11.690947Z"
        transform="matrix(-1 0 0 1 253.943659 0.197824)"
        strokeWidth="0.512"
      />
    </g>
  </svg>
);

const Footer = () => (
  <footer id="hw__footer">
    {Daisy8}
    {Butterfly}

    <div className="hw__footer-body">
      <p className="hw__footer-subtitle">Join My Network</p>
      <header>Bringing ideas to life.</header>
      <header>Let's innovate together!</header>
      <Button
        name="Connect"
        variant="outlined"
        size="large"
        link={info.socialLinks.LinkedIn.link}
        icon={info.socialLinks.LinkedIn.icon}
      />

      <div className="hw__footer">
        <div className="hw__footer-left">
          <span>© 2023 By </span>
          <a
            className="hw__link-text hw__font-georgia"
            href="https://blog.harriswong.top/author"
            target="_blank"
            rel="noreferrer"
          >
            Harris Wong
          </a>
          <span>. All rights reserved.</span>
        </div>

        <div className="hw__footer-right">
          {info.socialLinks.footer.map((item) => (
            <Button
              key={item.name}
              tooltip={item.name}
              link={item.link}
              icon={item.icon}
              variant="outlined"
            />
          ))}
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
