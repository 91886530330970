import { useEffect } from "react";

const useScroll = (elementClass, animation) => {
  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelectorAll(`.${elementClass}`);
      if (element[0]) {
        const elementPosition =
          element[0].getBoundingClientRect().top + window.scrollY;
        const windowBottom = window.scrollY + window.innerHeight;
        if (windowBottom > elementPosition) {
          element.forEach(ele => {
            ele.classList.add('animate__animated', animation);
          });
          window.removeEventListener("scroll", handleScroll)
        }
      }
    };
    // 初始化时触发一次，否则就需要鼠标划动后才能触发。这样可以适配不同尺寸的屏幕，毕竟移动端的Skills板块是看不到的，所以希望划到Skills再来显示动画。
    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });
};

export default useScroll;
