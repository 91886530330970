import { useState, useEffect } from "react";
import "./ScrollingText.css";

const ScrollingText = ({ texts, className }) => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((prev) => (prev < texts.length - 1 ? prev + 1 : 0));
    }, 2000);

    return () => clearInterval(interval);
  });

  return (
    <div className={`scroll-container ${className}`}>
      {texts.map((text, index) => (
        <span
          key={index}
          className={`text-item${index === current ? " show" : ""}${
            index === (current - 1 < 0 ? texts.length - 1 : current - 1)
              ? " prev"
              : ""
          }`}
        >
          {text}
        </span>
      ))}
    </div>
  );
};

export default ScrollingText;
