import React from "react";
import Section from "../../components/Section/Section";
import "./Contact.css";
import { MdEmail, MdLocationOn } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import Envelop from "../../assets/Contact/Contact.svg";
import useScroll from "../../hooks/useScroll";
import Button from "../../components/Button/Button";

const Contact = () => {
  const [subject, setSubject] = React.useState("");
  const [body, setBody] = React.useState("");

  const changeSubject = (event) => setSubject(event.target.value);
  const changeBody = (event) => setBody(encodeURIComponent(event.target.value));

  useScroll("hw__contact", "animate__fadeInUp");

  return (
    <Section
      title="contact"
      desc="If you are interested or want to work with me, don't hesitate to drop me an email."
    >
      <div className="hw__contact">
        <div className="hw__contact-illustration">
          <img src={Envelop} alt="envelop" />
        </div>

        <div className="hw__contact-form">
          <div className="hw__contact-info-wrap">
            <div className="hw__contact-info">
              <FaUserCircle />
              <span>Shisong</span>
            </div>

            <div className="hw__contact-info">
              <MdEmail />
              <span>huangshisong89@gmail.com</span>
            </div>

            <div className="hw__contact-info">
              <MdLocationOn />
              <span>United Kingdom</span>
            </div>
          </div>

          <input type="text" placeholder="Subject" onChange={changeSubject} />

          <textarea
            name=""
            id=""
            rows="10"
            placeholder="Message"
            onChange={changeBody}
          />

          <div className="hw__contact-send">
            <Button
              name="Email"
              icon={<IoSend />}
              size="large"
              link={`mailto:huangshisong89@gmail.com?subject=${subject}&body=${body}`}
            />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Contact;
